'use client';

import React, { useRef } from 'react';
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react-v2/24/outline';
import { useFullscreen } from './webinar-util';

interface LiveWebinarProps {
  buildUrl: string;
  title: string;
}

export const LiveWebinar: React.FC<LiveWebinarProps> = ({
  buildUrl,
  title,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(videoContainerRef);

  return (
    <div ref={videoContainerRef} className="relative h-[650px] w-full">
      <iframe
        allow="camera *;microphone *;display-capture *"
        className="h-full w-full border-0"
        src={buildUrl}
        title={title || 'Webinar'}
      ></iframe>
      <button
        aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
        className="absolute right-4 top-4 hidden rounded-full bg-black/50 p-2 text-white hover:bg-black/70 sm:block"
        onClick={toggleFullscreen}
      >
        {isFullscreen ? (
          <ArrowsPointingInIcon className="h-5 w-5" />
        ) : (
          <ArrowsPointingOutIcon className="h-5 w-5" />
        )}
      </button>
    </div>
  );
};
