import { useEffect, useState } from 'react';
import analytics from '@analytics';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import numeral from 'numeral';
import useSWR from 'swr';
import { getCookieConsent } from '@leaf/helpers';
import '@leaf/ui/styles';
import { useApollo } from '@/apollo/client';
import { CurrentCompanyQuery } from '@/apollo/generated';
import Analytics from '@/components/core/analytics';
import ContextProvider from '@/contexts/context-provider';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@/styles/hermes.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/video/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import type { AppProps } from 'next/app';

interface PageProps {
  currentCompany?: NonNullable<CurrentCompanyQuery['currentCompany']>;
  initialApolloState?: NormalizedCacheObject;
  isPreviewMode?: boolean;
}

interface Props extends AppProps<PageProps> {
  pageProps: PageProps;
}

const CustomApp: React.ComponentType<Props> = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  useEffect(() => {
    const cookieConsent = getCookieConsent();
    setAnalyticsAllowed(!!cookieConsent && cookieConsent.analytics);
    analytics.loadIfConsented('hermes');
    analytics.page(location.href, document.title, {
      path: location.pathname,
      referrer: window.frames.self.document.referrer,
      search: location.search,
      url: location.href,
    });

    // Numeral custom locale for capitals like "B" for billion instead of "b"
    // This is otherwise all the same as the 'en-au' locale
    if (numeral.locales['investor-hub'] === undefined) {
      numeral.register('locale', 'investor-hub', {
        abbreviations: {
          billion: 'B',
          million: 'M',
          thousand: 'K',
          trillion: 'T',
        },
        currency: {
          symbol: '$',
        },
        delimiters: {
          decimal: '.',
          thousands: ',',
        },
        ordinal: function (number) {
          const b = number % 10;
          return ~~((number % 100) / 10) === 1
            ? 'th'
            : b === 1
            ? 'st'
            : b === 2
            ? 'nd'
            : b === 3
            ? 'rd'
            : 'th';
        },
      });
    }
    // set the loaded local.
    numeral.locale('investor-hub');
  }, []);

  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => {
      NProgress.start();
    };
    const onRouteChangeComplete = (url: string) => {
      NProgress.done();
      const trackingProperties = {
        path: location.pathname,
        referrer: window.frames.self.document.referrer,
        search: location.search,
        url: location.href,
      };
      analytics.page(url, document.title, trackingProperties);
      if (
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
        process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID &&
        !!window.gtag
      ) {
        window.gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
          page_path: url,
        });
      }
    };
    const onRouteChangeError = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeError);
    };
  }, [router]);

  useSWR(
    '/api/hermes/visitor/token',
    (url) =>
      fetch(url).then((r) => {
        return r.json();
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useSWR(
    '/api/hermes/visitor/segment',
    (url) =>
      fetch(url).then((r) => {
        return r.json();
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { currentCompany } = pageProps;

  return (
    <>
      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
        analyticsAllowed && (
          <>
            <Script
              id="ga-script"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
              strategy="afterInteractive"
            />
            <Script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {
                page_path: window.location.pathname,
              });
              `,
              }}
              id="ga-script-init"
              strategy="afterInteractive"
            />
          </>
        )}

      <ApolloProvider client={apolloClient}>
        <ContextProvider>
          <Analytics currentCompany={currentCompany}>
            <Component {...pageProps} />
          </Analytics>
        </ContextProvider>
      </ApolloProvider>
      <SpeedInsights />
    </>
  );
};

export default CustomApp;
