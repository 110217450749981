import { useCallback, useEffect, useState } from 'react';
import analytics from '@analytics';
import clsx from 'clsx';
import Script from 'next/script';
import CodeInput from 'react-code-input';
import {
  Button,
  Typography,
  useResendConfirmUserMutation,
  useVerifyUserEmailByTokenMutation,
} from '../../../index';
import { formatError } from '../../../lib/utils';
import { useAlert } from '../../context/alert-context';
import { useSignUpOnPage } from '../../context/sign-up-on-page-context';
import styles from './verify-email.module.css';

const SignUpLiteVerify: React.ComponentType = () => {
  useEffect(() => {
    analytics.track('signup_modal_email_verify_viewed');
  }, []);
  const { formatAndShowError, showAlert } = useAlert();

  const { complete, user } = useSignUpOnPage();

  const [code, setCode] = useState<string | undefined>(undefined);
  const [error, setError] = useState('');
  const [isCodeInputMounted, setCodeInputMounted] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const [resendConfirmUser, { loading: resendConfirmUserLoading }] =
    useResendConfirmUserMutation();
  const [verifyUserEmailByToken] = useVerifyUserEmailByTokenMutation();

  async function onClickResend() {
    window.grecaptcha.ready(() => {
      if (process.env.NEXT_PUBLIC_RECAPTCHA_KEY) {
        window.grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, {
            action: 'submit',
          })
          .then(async (token) => {
            try {
              await resendConfirmUser({
                variables: {
                  captchaToken: token,
                  confirmationLink: false,
                },
              });

              showAlert({
                description:
                  'The email has been sent, please check your inbox.',
                variant: 'success',
              });
            } catch (error) {
              formatAndShowError(error);
            }
          });
      }
    });
  }

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    await verifyUserEmailByToken({
      variables: {
        token: code || '',
      },
    })
      .then(() => {
        analytics.track('signup_modal_email_verified');
        complete();
      })
      .catch((error) => {
        setCode('');
        setError(formatError(error));
        setSubmitting(false);

        setCodeInputMounted(false);
        setTimeout(() => {
          setCodeInputMounted(true);
        }, 0);
      });
  }, [verifyUserEmailByToken, code, complete]);

  useEffect(() => {
    if (code && code.length === 4) {
      onSubmit();
    }
  }, [code, onSubmit]);

  return (
    <>
      <div className="mb-6">
        <Typography align="left" variant="body-regular">
          {`We've sent an email to `}
          <span className="block font-semibold sm:inline">
            {user?.email ?? ''}.
          </span>
        </Typography>
        <Typography align="left" variant="body-regular">
          Please enter the code provided in the email to verify your account.
        </Typography>
      </div>
      <div className="space-y-6">
        {isCodeInputMounted ? (
          <CodeInput
            className={clsx(styles['code-input'], 'py-8')}
            disabled={isSubmitting}
            inputMode="numeric"
            name="code"
            value={code}
            onChange={setCode}
          />
        ) : (
          <div className="h-[46px]" />
        )}
        {!!error && (
          <Typography align="center" className="text-status-red">
            {error}
          </Typography>
        )}
        <Button
          fullWidth
          className="bg-white no-underline shadow-md"
          color="grey"
          disabled={resendConfirmUserLoading || isSubmitting}
          variant="tertiary"
          onClick={onClickResend}
        >
          {isSubmitting ? 'Verifying...' : 'Resend email'}
        </Button>
      </div>
      <Script
        id="recaptcha"
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
        strategy="afterInteractive"
      />
    </>
  );
};

export default SignUpLiteVerify;
