import { useCurrentCompany } from '@/contexts/current-company-context';

function useFeatureEnabled(feature: string): boolean {
  const { currentCompany } = useCurrentCompany();
  const { featuresEnabled } = currentCompany;

  return featuresEnabled.includes(feature);
}

// Example { distributionMilestone2: 'distribution_milestone_2' }
export const FLAGS = {
  featuredAnnouncements: 'featured_announcements',
  germanAnnouncementTranslations: 'german_announcement_translations',
  sppEnabled: 'spp_enabled',
  useAnnouncementSidebar: 'use_announcement_sidebar',
  websitePageBuilder: 'website_page_builder',
};

export default useFeatureEnabled;
