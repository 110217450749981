import { useCallback, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import {
  CurrentInvestorUserQuery,
  HermesGenericModal,
  Typography,
  useResendConfirmUserMutation,
} from '../../index';
import { setReturnToCookie } from '../../lib/utils';
import { useAlert } from '../context/alert-context';
import {
  AttemptedAction as SignUpOnPageAttemptedAction,
  SignUpOnPageProvider,
} from '../context/sign-up-on-page-context';

interface Props {
  attemptedAction?: SignUpOnPageAttemptedAction;
  companyId: string;
  companyName: string;
  currentInvestorUser: CurrentInvestorUserQuery['currentInvestorUser'];
  isUK: boolean;
  listingKey: string;
  login?: (
    companyProfileId: string,
    email: string,
    password: string,
    rememberMe?: boolean,
    shareholderHashId?: string
  ) => Promise<void>;
  logoUrl: string;
  marketKey: string;
  marketListingKey: string;
  mediaCommentId?: string;
  mediaId?: string;
  onClose(): void;
  open: boolean;
}

export const SignUpOnPageModal: React.ComponentType<Props> = ({
  attemptedAction,
  companyId,
  companyName,
  currentInvestorUser,
  isUK,
  listingKey,
  login,
  logoUrl,
  marketKey,
  marketListingKey,
  mediaCommentId,
  mediaId,
  onClose,
  open,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [sendConfirmUser, { loading: sendConfirmUserLoading }] =
    useResendConfirmUserMutation();
  const { formatAndShowError, showAlert } = useAlert();

  const router = useRouter();

  const isNotSignedIn = !currentInvestorUser;
  const isNotVerified = currentInvestorUser && !currentInvestorUser.confirmedAt;
  const completedAll =
    currentInvestorUser &&
    currentInvestorUser.confirmedAt &&
    currentInvestorUser.username;

  const title = useMemo(() => {
    if (isNotSignedIn) {
      return null;
    }

    return 'Nearly there!';
  }, [isNotSignedIn]);

  const content = useMemo(() => {
    if (isNotSignedIn && attemptedAction) {
      return (
        <SignUpOnPageProvider
          attemptedAction={attemptedAction}
          companyId={companyId}
          companyName={companyName}
          isUK={isUK}
          listingKey={listingKey}
          login={login}
          logoUrl={logoUrl}
          marketKey={marketKey}
          marketListingKey={marketListingKey}
          mediaCommentId={mediaCommentId}
          mediaId={mediaId}
        />
      );
    }

    if (isNotVerified) {
      return (
        <Typography align="center">
          We need to verify your email before you can ask participate.
        </Typography>
      );
    }

    if (isNotSignedIn) {
      return (
        <SignUpOnPageProvider
          attemptedAction={attemptedAction}
          companyId={companyId}
          companyName={companyName}
          isUK={isUK}
          listingKey={listingKey}
          login={login}
          logoUrl={logoUrl}
          marketKey={marketKey}
          marketListingKey={marketListingKey}
          mediaCommentId={mediaCommentId}
          mediaId={mediaId}
        />
      );
    }

    return (
      <Typography align="center">
        You need to choose a username before you ask participate.
      </Typography>
    );
  }, [
    isNotSignedIn,
    attemptedAction,
    isNotVerified,
    companyId,
    companyName,
    isUK,
    listingKey,
    login,
    logoUrl,
    marketKey,
    marketListingKey,
    mediaCommentId,
    mediaId,
  ]);

  const sendEmailVerification = useCallback(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window.grecaptcha !== 'undefined'
    ) {
      return window.grecaptcha.ready(() => {
        if (process.env.NEXT_PUBLIC_RECAPTCHA_KEY) {
          window.grecaptcha
            .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, {
              action: 'submit',
            })
            .then(async (token) => {
              try {
                await sendConfirmUser({
                  variables: {
                    captchaToken: token,
                  },
                });

                showAlert({
                  description:
                    'The email has been sent, please check your inbox.',
                  variant: 'success',
                });
              } catch (error) {
                formatAndShowError(error);
              }
            });
        }
      });
    }

    return null;
  }, [formatAndShowError, sendConfirmUser, showAlert]);

  if (completedAll) return null;

  return (
    <HermesGenericModal
      bottomModal
      className="z-[100] mb-16"
      headerText={title}
      initialFocus={buttonRef}
      loading={sendConfirmUserLoading}
      open={open}
      primaryButtonAction={async () => {
        if (isNotSignedIn) {
          return;
        }

        if (isNotVerified) {
          router.push('/onboarding/verify-email');
          await sendEmailVerification();
          setReturnToCookie(router.asPath);
        } else {
          router.push('/onboarding/set-username');
          setReturnToCookie(router.asPath);
        }
      }}
      primaryButtonText={
        isNotSignedIn
          ? undefined
          : isNotVerified
          ? 'Verify email'
          : 'Set username'
      }
      secondaryButtonAction={isNotSignedIn ? undefined : onClose}
      secondaryButtonText={isNotSignedIn ? undefined : 'Cancel'}
      onClose={onClose}
    >
      {content}
    </HermesGenericModal>
  );
};
