import { useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { useTimeout } from 'usehooks-ts';
import { Typography, useCurrentInvestorUserLazyQuery } from '../../../index';
import { AttemptedAction } from '../../context/sign-up-on-page-context';

interface Props {
  attemptedAction?: AttemptedAction;
}

const SignUpLiteComplete: React.ComponentType<Props> = ({
  attemptedAction,
}) => {
  const [currentInvestorUserQuery] = useCurrentInvestorUserLazyQuery();

  // get current investor user to "reload" to page state
  const loadCurrentUser = () => currentInvestorUserQuery();

  useTimeout(loadCurrentUser, 3500);

  useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  return (
    <div className="flex flex-col items-start gap-8">
      <div className="flex justify-center">
        <CheckCircleIcon className="h-20 w-20 text-company-primary" />
      </div>

      <div>
        <Typography variant="heading-4">
          {attemptedAction === 'like' || attemptedAction === 'like-question'
            ? 'Liked successfully!'
            : attemptedAction === 'follow' ||
              attemptedAction === 'follow-question'
            ? 'Follow updated!'
            : `You're signed up! You can now post questions!`}
        </Typography>
      </div>
    </div>
  );
};

export default SignUpLiteComplete;
