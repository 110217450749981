import { useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  TextInput,
  useIsUsernameAvailableLazyQuery,
} from '../../../index';
import { passwordValidation } from '../../../lib/utils';
import { useAlert } from '../../context/alert-context';
import { useSignUpOnPage } from '../../context/sign-up-on-page-context';

interface FormData {
  firstName: string;
  lastName: string;
  password: string;
  username: string;
}

const SignUpLiteDetails: React.ComponentType = () => {
  useEffect(() => {
    analytics.track('signup_modal_enter_details_viewed');
  }, []);
  const [isUsernameAvailable] = useIsUsernameAvailableLazyQuery();
  const [showPassword, togglePassword] = useState(false);
  const { setStep, setUser, user } = useSignUpOnPage();
  const [prevUsername, setPrevUsername] = useState<string | undefined>('');

  const defaultValues = useMemo(() => {
    return {
      username: user?.email
        ? `${user?.email
            .split('@')[0]
            .replace(/[^A-Za-z0-9 -]/g, '_')}_${Math.floor(
            Math.random() * 900 + 100
          )}`
        : '',
    };
  }, [user?.email]);

  const { formatAndShowError } = useAlert();

  const { formState, handleSubmit, register, reset } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues,
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().trim().required('Required.'),
        lastName: yup.string().trim().required('Required.'),
        password: passwordValidation,
        username: yup
          .string()
          .required('Required.')
          .matches(/^\w+$/, 'Only letters, numbers and underscores allowed.')
          .test(
            'isUsernameAvailable',
            'Oops! That username is already taken. Try another name.',
            async (value) => {
              try {
                if (!value) return false;

                if (prevUsername === value) return true;
                setPrevUsername(value);

                const result = await isUsernameAvailable({
                  variables: {
                    username: value,
                  },
                });

                return !!result.data?.isUsernameAvailable;
              } catch (error) {
                return false;
              }
            }
          )
          .min(6, 'Minimum 6 characters.\n'),
      })
    ),
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, password, username }) => {
      setUser({
        email: user?.email ?? '',
        firstName,
        lastName,
        password,
        username,
      });

      const signUp = () =>
        fetch('/api/hermes/auth/signup', {
          body: JSON.stringify({
            confirmation_link: false,
            email: user?.email ?? '',
            first_name: firstName,
            last_name: lastName,
            password: password,
            referer: window.location.origin,
            unsubscribe: user?.unsubscribe,
            username: username,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });

      await signUp()
        .then(() => {
          analytics.track('signup_modal_signed_up');

          setStep('verify');
        })
        .catch(formatAndShowError);
    }
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-4 space-y-4">
        <div className="grid-cols-2 gap-3 lg:grid">
          <TextInput
            {...register('firstName')}
            autoComplete="given-name"
            className="shadow-md"
            error={!!formState.errors.firstName?.message}
            helperText={formState.errors.firstName?.message}
            id="firstName"
            label="First name"
          />
          <TextInput
            {...register('lastName')}
            autoComplete="family-name"
            className="shadow-md"
            error={!!formState.errors.lastName?.message}
            helperText={formState.errors.lastName?.message}
            id="lastName"
            label="Last name"
          />
        </div>
        <TextInput
          {...register('username')}
          autoComplete="username"
          className="shadow-md"
          description="Your username is publicly visible and cannot be changed."
          error={!!formState.errors.username?.message}
          helperText={formState.errors.username?.message}
          id="username"
          label="Username"
        />
        <TextInput
          {...register('password')}
          autoComplete="new-password"
          className="shadow-md"
          error={!!formState.errors.password?.message}
          helperText={
            formState.errors.password?.types ? (
              <>
                {Object.entries(formState.errors.password?.types).map(
                  ([type, message]) => (
                    <span key={type} className="block whitespace-pre leading-5">
                      {message}
                    </span>
                  )
                )}
              </>
            ) : undefined
          }
          id="password"
          label="Password"
          trailingIcon={showPassword ? EyeOffIcon : EyeIcon}
          trailingIconAction={() => togglePassword(!showPassword)}
          type={showPassword ? 'text' : 'password'}
        />
      </div>
      <div className="mt-12 space-y-4">
        <Button
          fullWidth
          disabled={formState.isSubmitting}
          testId="sign-up-btn"
          type="submit"
          onClick={() => analytics.track('signup_form_submitted')}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default SignUpLiteDetails;
